import React from 'react';
import { useSelector } from 'react-redux';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useSettings } from '@wix/tpa-settings/react';
import { LayoutType } from 'settings/consts';
import { groupsListWidgetSettingsParams as settingsParams } from 'settings/groups-list-widget/settings';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { selectGroups } from 'store/groups/selectors';

import { EGroupPartition } from 'api/groups/types';

import { ListLayout } from './ListLayout';
import { GridLayout } from './GridLayout';
import { SideBySideLayout } from './SideBySideLayout';

import { LayoutProps } from './types';

type LayoutRootProps = {};

export const LayoutRoot: React.FC<LayoutRootProps> = () => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  const { groups$ } = useController();
  const state = useSelector(selectGroups(EGroupPartition.ALL));

  const limit = settings.get(settingsParams.numberGroups);
  const sortBy = settings.get(settingsParams.sortBy);

  const layoutProps: LayoutProps = {
    ...state,
    onRetry: handleRetry,
    batchLength: limit,
  };

  useDidUpdate(() => {
    groups$.query({
      namespace: EGroupPartition.ALL,
      partition: EGroupPartition.ALL,
      limit,
      sort: sortBy,
    });
  }, [limit, sortBy]);

  switch (settings.get(settingsParams.groupListLayout)) {
    case LayoutType.grid:
      return <GridLayout {...layoutProps} />;

    case LayoutType.sideBySide:
      return isMobile ? (
        <GridLayout {...layoutProps} />
      ) : (
        <SideBySideLayout {...layoutProps} />
      );

    case LayoutType.listWidget:
    default:
      return <ListLayout {...layoutProps} />;
  }

  function handleRetry() {
    groups$.query({
      limit,
      offset: 0,
      namespace: EGroupPartition.ALL,
      partition: EGroupPartition.ALL,
    });
  }
};

LayoutRoot.displayName = 'LayoutRoot';
